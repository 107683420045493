import {
  SET_LAYOUT, SET_SORT, SET_VISIBLE_ITEMS, SET_FILTER,
  WISHLIST_ADD_ITEM, WISHLIST_REMOVE_ITEM,
  OPEN, CLOSE,
  SET_PAGE
 } from './types';

export function setLayout(layout) {
  if (layout === 'grid') {
    return {
      type: SET_LAYOUT,
      layout: 'grid'
    }
  }

  if (layout === 'list') {
    return {
      type: SET_LAYOUT,
      layout: 'list'
    }
  }

  return null;
}

export function setSort(sort) {
  return {
    type: SET_SORT,
    sort
  }
}

export function itemsToShow(number) {
  return {
    type: SET_VISIBLE_ITEMS,
    number
  }
}

export function setPage(page) {
  return {
    type: SET_PAGE,
    page
  }
}

export function setFilter(prevFilters, filter) {
  const newFilter = {
    ...prevFilters,
    ...filter
  };

  return {
    type: SET_FILTER,
    filter: newFilter
  }
}

export function addToWishlist(item) {
  return {
    type: WISHLIST_ADD_ITEM,
    item
  }
}

export function removeFromWishlist(item) {
  return {
    type: WISHLIST_REMOVE_ITEM,
    item
  }
}

export function openOverlay() {
  return {
    type: OPEN
  }
}

export function closeOverlay() {
  return {
    type: CLOSE
  }
}